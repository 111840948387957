import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiDomain } from "../config";
import Session, { signOut } from "supertokens-auth-react/recipe/session";
import DesktopNavBar from "./DesktopNavBar";
import MobileNavBar from "./MobileNavBar";

const NavBar = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const fetchSessionInfo = async () => {
      if (await Session.doesSessionExist()) {
        try {
          const response = await axios.get(getApiDomain() + "/sessioninfo");
          const first_name = response.data.metadata?.first_name;
          if (first_name) {
            setFirstName(first_name);
          }
        } catch (error) {
          console.error("Error fetching session info:", error);
        }
      }
    };

    fetchSessionInfo();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLoginClick = () => {
    navigate("/auth");
  };

  const handleUserClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleInboxClick = () => {
    navigate("/inbox");
  };

  const handleLogoutClick = async () => {
    await signOut();
    navigate("/");
  };

  return isMobile ? (
    <MobileNavBar
      handleUserClick={handleUserClick}
      handleLoginClick={handleLoginClick}
      handleProfileClick={handleProfileClick}
      handleInboxClick={handleInboxClick}
      handleLogoutClick={handleLogoutClick}
    />
  ) : (
    <DesktopNavBar
      firstName={firstName}
      handleUserClick={handleUserClick}
      handleLoginClick={handleLoginClick}
      handleProfileClick={handleProfileClick}
      handleInboxClick={handleInboxClick}
      handleLogoutClick={handleLogoutClick}
    />
  );
};

export default NavBar;
