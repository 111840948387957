import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { recipeDetails } from "../config";
import {
  BlogsIcon,
  CelebrateIcon,
  GuideIcon,
  SeparatorLine,
  SignOutIcon,
} from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";

interface ILink {
  name: string;
  onClick: () => void;
  icon: string;
}

export default function PrivacyContentView() {
  const navigate = useNavigate();

  async function onClicked() {
    window.location.href = "https://www.snowingpine.com";
  }

  function openLink(url: string) {
    window.open(url, "_blank");
  }

  const links: ILink[] = [
    {
      name: "Home",
      onClick: onClicked,
      icon: GuideIcon,
    },
  ];

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.innerContent}>
          <h1>PRIVACY POLICY</h1>
          <p>
            This is the Privacy Policy of Snowing Pine Stories and Potomac
            Stories. ("Snowingpine"). For Snowingpine, the protection and
            confidentiality of your data is of the utmost importance.
          </p>
          <p>
            Snowingpine only collects and uses your Patreon subscription data to
            determine if you are allowed access to our content, which is
            processed strictly within the legal limits of the data protection
            law of the Kingdom of Norway, the EU General Data Protection
            Regulation no. 2016/679 and UK GDPR (collectively, the "GDPR") as
            incorporated in Norwegian law, in addition to applicable data
            protection laws in the United States, including but not limited to,
            the California Consumer Privacy Act (“CCPA”). Terms that we use in
            this document like "personal data", "processing", "data controller"
            and "data processor" shall have the meaning as defined therein.
          </p>
          <p>
            We may modify this Privacy Policy at any time. All changes will be
            effective immediately upon posting to our website. Material changes
            will be conspicuously posted on our website or otherwise
            communicated to you. The latest version of the Privacy Policy is
            always accessible at <b>snowingpine.com/legal/privacy</b>
          </p>
        </div>
        <div className={styles.bottomLinksContainer}>
          {links.map((link) => (
            <div className={styles.link} key={link.name}>
              <img
                className={styles.linkIcon}
                src={link.icon}
                alt={link.name}
              />
              <div role={"button"} onClick={link.onClick}>
                {link.name}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <img className="separator-line" src={SeparatorLine} alt="separator" /> */}
    </>
  );
}
