import { useSelector } from "react-redux";
import { RootState } from "./store";
import styles from "./App.module.css";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Roadmap from "./Roadmap";
import Profile from "./Profile";
import { PreBuiltUIList, ComponentWrapper } from "./config";
import PrivacyPolicy from "./PrivacyPolicy";
import Home from "./Home";
import Settings from "./Settings";
import Alert from "./Alert";
import { SW_UPDATE } from "./types";
import NotFoundPage from "./NotFound";
import { useTheme } from "./ThemeLoader";
import AuthorDashboard from "./AuthorDashboard";
import CreateFiction from "./CreateFiction";
import Submissions from "./Submissions";
import AdminDashboard from "./AdminDashboard";
import ProtectedRoute from "./utils/ProtectedRoute";
import AdminSubmissions from "./AdminSubmissions";
import AuthorFictionDash from "./AuthorFictionDash";
import AuthorAddChapter from "./AuthorAddChapter";
import Reader from "./Reader";
import FictionPage from "./FictionPage";
import UnderConstruction from "./UnderConstruction";
import AuthorPatreon from "./AuthorPatreon";
import { HelmetProvider } from "react-helmet-async";
import axios from "axios";
import { useEffect, useState } from "react";
import { getApiDomain } from "./config";
import Session from "supertokens-auth-react/recipe/session";
import UserOnboarding from "./UserOnboarding";
import Cookies from "js-cookie";
import ChangelogModal from "./ChangelogModal";
import NotificationPermissionModal from "./NotificationPermissionModal";

const CURRENT_CHANGELOG_VERSION = 9;

const changelog = {
  id: 9,
  version: "Beta 2.0.9",
  title: "Some Fixes and UI Changes",
  description: `
                <p><strong>+</strong> Fixed the chapter navigation on desktop not rendering correctly on lower widths if it contained the fiction page button. (Bug found by discord user @johnballzach)</p>
                <p><strong>+</strong> Added a cross-platform function to track if a chapter has been read. Read chapters will be shown in gray on the table of contents. Unfortunately not retroactive, soz. (Suggestion by discord user @volrad)</p>
                <p><strong>+</strong> Reworked the fiction page for mobile and low desktop widths. It should now be a bit more comfortable to use.</p>`,
  date: new Date("2024-09-26").toISOString(),
};

function App() {
  // const isServiceWorkerInitialized = useSelector((state: RootState) => state.serviceWorkerInitialized);
  // const serviceWorkerRegistration = useSelector((state: RootState) => state.serviceWorkerRegistration);
  const isServiceWorkerUpdated = useSelector(
    (state: RootState) => state.serviceWorkerUpdated
  );
  const theme = useTheme();
  const [apiData, setApiData] = useState(null);
  const [hasUsername, setHasUsername] = useState(false);
  const [sessionExists, setSessionExists] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [showChangelog, setShowChangelog] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      if (await Session.doesSessionExist()) {
        setSessionExists(true);
      } else {
        setSessionExists(false);
      }
      console.log("Session Exists", sessionExists);
    };

    checkSession();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionExists) {
          const response = await axios.get(getApiDomain() + "/sessioninfo");
          setApiData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sessionExists]);

  useEffect(() => {
    if (apiData) {
      const choseUsername = (apiData as any).metadata?.chose_username;
      if (choseUsername) {
        setHasUsername(choseUsername);
        setFinishedLoading(true);
      } else {
        setHasUsername(false);
        setFinishedLoading(true);
      }

      console.log("Has Username: ", hasUsername);
    }
  }, [apiData]);

  useEffect(() => {
    if (sessionExists) {
      console.log("Checking for changelog");
      const changelogVersion = parseInt(
        Cookies.get("changelog-version") || "0",
        10
      );
      if (changelogVersion < CURRENT_CHANGELOG_VERSION) {
        setShowChangelog(true);
        Cookies.set("changelog-version", CURRENT_CHANGELOG_VERSION.toString());
      }
    }
  }, [sessionExists]);

  useEffect(() => {
    if (sessionExists) {
      const notificationRequestShown =
        Cookies.get("notification-request-shown") === "true";
      if (!notificationRequestShown) {
        const permission = Notification.permission;
        if (permission !== "granted") {
          setShowNotificationModal(false); // supposed to be true but I disabled it for now
          Cookies.set("notification-request-shown", "true");
        } else {
          handleRequestNotificationPermission();
        }
      }
    }
  }, [sessionExists]);

  const updateServiceWorker = () => {
    window.location.reload();
  };

  const handleRequestNotificationPermission = async () => {
    try {
      console.log("Requesting notification permission");
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        console.error("Notification permission not granted");
        setShowNotificationModal(false);
        return;
      }
      console.log("Notification permission granted");

      setShowNotificationModal(false);

      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BIINfx4wWl_arxT3Q2_Q1aOQY-9eezGXyyoFdQPG937DEUImLK4MvCITXdvjTXI0ylDVw2FAjBtc-bpp5kQXZGM",
      });

      console.log("Push subscription:", subscription);

      await axios.post(
        getApiDomain() + "/api/push_subscription_data",
        subscription
      );

      console.log("Subscription data sent to server");
      setShowNotificationModal(false);
    } catch (error) {
      console.error(
        "Error requesting notification permission or subscribing:",
        error
      );
    }
  };

  if (sessionExists && finishedLoading && !hasUsername) {
    return (
      <div
        className={`${styles.App} ${
          theme === "dark" ? "darkMode" : "lightMode"
        }`}
      >
        <UserOnboarding />
      </div>
    );
  }

  return (
    <>
      {showChangelog && (
        <ChangelogModal
          onClose={() => setShowChangelog(false)}
          theme={theme}
          changelog={changelog}
        />
      )}
      {showNotificationModal && (
        <NotificationPermissionModal
          theme={theme}
          onRequestPermission={handleRequestNotificationPermission}
          onClose={() => setShowNotificationModal(false)}
        />
      )}
      <div
        className={`${styles.App} ${
          theme === "dark" ? "darkMode" : "lightMode"
        }`}
      >
        <div>
          {isServiceWorkerUpdated && (
            <Alert
              text="There is a new version available."
              buttonText="Update"
              type={SW_UPDATE}
              onClick={updateServiceWorker}
            />
          )}
        </div>
        <HelmetProvider>
          <Router>
            <Routes>
              {getSuperTokensRoutesForReactRouterDom(
                require("react-router-dom"),
                PreBuiltUIList
              )}

              <Route path="/" element={<Home />} />
              <Route path="onboarding" element={<UserOnboarding />} />
              <Route path="/roadmap" element={<Roadmap />} />
              <Route
                path="/settings"
                element={
                  <SessionAuth>
                    <Settings />
                  </SessionAuth>
                }
              />
              <Route path="/support" element={<UnderConstruction />} />
              <Route path="/blog" element={<UnderConstruction />} />
              <Route path="/inbox" element={<UnderConstruction />} />

              <Route path="/legal/privacy" element={<PrivacyPolicy />} />

              <Route
                path="/author-dashboard"
                element={
                  <SessionAuth>
                    <AuthorDashboard />
                  </SessionAuth>
                }
              />
              <Route
                path="/author-dashboard/patreon"
                element={
                  <SessionAuth>
                    <AuthorPatreon />
                  </SessionAuth>
                }
              />
              <Route
                path="/author-dashboard/submissions"
                element={
                  <SessionAuth>
                    <Submissions />
                  </SessionAuth>
                }
              />
              <Route
                path="/author-dashboard/submissions/create"
                element={
                  <SessionAuth>
                    <CreateFiction />
                  </SessionAuth>
                }
              />

              <Route
                path="/admin-dashboard"
                element={
                  <SessionAuth>
                    <ProtectedRoute requiredPermission={3}>
                      <AdminDashboard />
                    </ProtectedRoute>
                  </SessionAuth>
                }
              />
              <Route
                path="/admin-dashboard/submissions"
                element={
                  <SessionAuth>
                    <ProtectedRoute requiredPermission={3}>
                      <AdminSubmissions />
                    </ProtectedRoute>
                  </SessionAuth>
                }
              />

              <Route
                path="/author-dashboard/dashboard/:id"
                element={
                  <SessionAuth>
                    <AuthorFictionDash />
                  </SessionAuth>
                }
              />

              <Route
                path="/author-dashboard/chapters/new/:id"
                element={
                  <SessionAuth>
                    <AuthorAddChapter />
                  </SessionAuth>
                }
              />

              <Route
                path="/profile"
                element={
                  <SessionAuth>
                    <UnderConstruction />
                  </SessionAuth>
                }
              />

              <Route path="/fiction/:fictionid" element={<FictionPage />} />
              <Route
                path="/fiction/:fictionid/:title"
                element={<FictionPage />}
              />

              <Route
                path="/fiction/:fictionid/:title/chapter/:chapterid"
                element={<Reader />}
              />
              <Route
                path="/fiction/:fictionid/:title/chapter/:chapterid/:chaptertitle"
                element={<Reader />}
              />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Router>
        </HelmetProvider>
      </div>
    </>
  );
}

export default App;
