import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppWithCookieConsent from "./AppWithCookieConsent";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import configureStore from "./store";
import { SW_INIT, SW_UPDATE } from "./types";
import InstallBanner from "./InstallBanner";
import { SuperTokensConfig, ComponentWrapper } from "./config";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";

const store = configureStore();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
SuperTokens.init(SuperTokensConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SuperTokensWrapper>
        <ComponentWrapper>
          <AppWithCookieConsent>
            <App />
            {/* <InstallBanner /> */}
          </AppWithCookieConsent>
        </ComponentWrapper>
      </SuperTokensWrapper>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
});
