import React, { useState, useRef } from "react";
import styles from "./DesktopNavBar.module.css";
import stylesDark from "./DesktopNavBarDark.module.css";
import {
  RoadmapWhite,
  PenFilledBlack,
  WordlinesBlack,
  MailFillBlack,
  NotifBellFilledBlack,
  ProfileFillBlack,
  LightModeWhiteFill,
  DarkModeBlack,
  SignOutIcon,
  SignOutIconWhite,
  BlogsIconWhite,
  HomeIconWhite,
  MailFillWhite,
  NotifBellFilledWhite,
  ProfileFillWhite,
} from "../assets/images/index";
import snowingPineLogo from "../assets/images/snowingpine_logo.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "../utils/updatemetadata";

interface DesktopNavBarProps {
  firstName: any;
  handleUserClick: () => void;
  handleLoginClick: () => void;
  handleProfileClick: () => void;
  handleInboxClick: () => void;
  handleLogoutClick: () => void;
}

const DesktopNavBar: React.FC<DesktopNavBarProps> = ({
  firstName,
  handleUserClick,
  handleLoginClick,
  handleProfileClick,
  handleInboxClick,
  handleLogoutClick,
}) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const dropdownTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const isDarkMode = Cookies.get("theme") === "dark";
  const currentStyles = isDarkMode ? stylesDark : styles;

  const handleMouseEnter = () => {
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current);
      dropdownTimeoutRef.current = null;
    }
    setShowDropdownMenu(true);
  };

  const handleMouseLeave = () => {
    dropdownTimeoutRef.current = setTimeout(() => {
      setShowDropdownMenu(false);
    }, 100);
  };

  async function toggleTheme() {
    const newTheme = isDarkMode ? "light" : "dark";
    Cookies.set("theme", newTheme, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    await updateMetadata({ preferences: { theme: newTheme } });
    window.location.reload();
  }

  return (
    <>
      <div className={currentStyles.navBar}>
        <div className={currentStyles.topRow}>
          <div className={currentStyles.insideRow}>
            <a
              href="https://www.snowingpine.com"
              className={currentStyles.logo}
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <img
                className={currentStyles.logoImage}
                src={snowingPineLogo}
                alt="Snowing Pine Stories Logo"
              />
              Snowing Pine Stories
            </a>
            <div className={currentStyles.userSection}>
              <div className={currentStyles.notifications}>
                <img
                  src={isDarkMode ? NotifBellFilledWhite : NotifBellFilledBlack}
                  alt="Notifications"
                />
              </div>
              <div className={currentStyles.messages}>
                <img
                  src={isDarkMode ? MailFillWhite : MailFillBlack}
                  alt="Inbox"
                />
              </div>
              {firstName ? (
                <div
                  className={currentStyles.userIcon}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <button className={currentStyles.userButton}>
                    {firstName}
                  </button>
                </div>
              ) : (
                <div
                  className={currentStyles.userIcon}
                  onClick={handleLoginClick}
                >
                  <button
                    className={currentStyles.userButton}
                    onClick={handleLoginClick}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${currentStyles.bottomRow}`}>
          <div className={currentStyles.insideRow}>
            <ul className={currentStyles.navLinks}>
              <li>
                <a href="https://www.snowingpine.com">
                  <img src={WordlinesBlack} alt="Read" />
                  Read
                </a>
              </li>
              <li>
                <a
                  href="/author-dashboard"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/author-dashboard");
                  }}
                >
                  <img src={PenFilledBlack} alt="Write" />
                  Write
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/blog");
                  }}
                >
                  <img src={BlogsIconWhite} alt="Blog" />
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/roadmap"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/roadmap");
                  }}
                >
                  <img src={RoadmapWhite} alt="Roadmap" />
                  Roadmap
                </a>
              </li>
            </ul>
            <input
              type="text"
              className={currentStyles.searchBar}
              placeholder="Search title..."
            />
          </div>
        </div>
      </div>
      {showDropdownMenu && (
        <div
          className={currentStyles.dropdownMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={currentStyles.dropdownItem}
            onClick={handleProfileClick}
          >
            <img
              src={isDarkMode ? ProfileFillWhite : ProfileFillBlack}
              alt="Profile"
            />{" "}
            My Profile
          </div>
          <div
            className={currentStyles.dropdownItem}
            onClick={handleInboxClick}
          >
            <img src={isDarkMode ? MailFillWhite : MailFillBlack} alt="Inbox" />{" "}
            Inbox
          </div>
          <div className={currentStyles.dropdownItem} onClick={toggleTheme}>
            <img
              src={isDarkMode ? LightModeWhiteFill : DarkModeBlack}
              alt="Toggle Theme"
            />{" "}
            {isDarkMode ? "Light Mode" : "Dark Mode"}
          </div>
          <div
            className={currentStyles.dropdownItem}
            onClick={handleLogoutClick}
          >
            <img
              src={isDarkMode ? SignOutIconWhite : SignOutIcon}
              alt="Logout"
            />{" "}
            Logout
          </div>
        </div>
      )}
    </>
  );
};

export default DesktopNavBar;
