import ContentView from "./ContentView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import styles from "../styles/GlobalDashboardStyles.module.css";
import Loading from "../components/Loading";
import DashboardNavBar from "../components/AuthorNav/DashboardNavBar";
import DashboardInnerNavBar from "../components/AuthorNav/DashboardInnerNavBar";
import Cookies from "js-cookie";
import MobileNotDoneChecker from "../components/MobileNotDoneChecker";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { getApiDomain } from "../config";

export default function AuthorFictionDash() {
  const sessionContext = useSessionContext();
  const { id } = useParams<{ id: string }>();
  const [isCreator, setIsCreator] = useState<boolean | null>(null);
  const [title, setTitle] = useState<string | undefined>(undefined);

  if (!id) {
    throw new Error("Fiction ID is required");
  }

  const fictionId = parseInt(id, 10);

  useEffect(() => {
    const checkIsCreator = async () => {
      try {
        const response = await axios.get(
          `${getApiDomain()}/api/fictions/is_creator?id=${id}`
        );
        setIsCreator(response.data.is_creator);
        setTitle(response.data.title);
      } catch (error) {
        console.error("Error checking if user is the creator:", error);
        setIsCreator(false);
        setTitle(undefined);
      }
    };

    checkIsCreator();
  }, [fictionId]);

  if (sessionContext.loading === true || isCreator === null) {
    return <Loading />;
  }

  const devAuthorDashView = Cookies.get("devAuthorDashView");

  if (!devAuthorDashView && window.innerWidth < 900) {
    return <MobileNotDoneChecker />;
  }

  if (!isCreator) {
    return (
      <>
        <div className={styles.fill} id={styles.homeContainer}>
          <DashboardNavBar />
          <div className={styles.contentContainer}>
            <DashboardInnerNavBar location="Fiction Dashboard" path="Home" />
            <div className={styles.accessDeniedContainer}>
              <h2>Access Denied</h2>
              <p>You are not the creator of this fiction.</p>
              <p>
                If you are certain you should have access to this content,
                please <a href="/support">open a support ticket</a>.
              </p>
              <button
                onClick={() =>
                  (window.location.href = "https://www.snowingpine.com")
                }
              >
                Return home
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.fill} id={styles.homeContainer}>
        <DashboardNavBar />
        <div className={styles.contentContainer}>
          <DashboardInnerNavBar
            location="Fiction Dashboard"
            path="Home"
            subpath={title}
          />
          <ContentView fictionId={id} />
        </div>
      </div>
    </>
  );
}
