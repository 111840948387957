import React, { useState, useEffect } from "react";
import styles from "./MobileNavBar.module.css";
import stylesDark from "./MobileNavBarDark.module.css";
import {
  LightModeWhiteFill,
  DarkModeBlack,
  MailFillBlack,
  NotifBellFilledBlack,
  ProfileFillBlack,
  HamburgerMenuBlack,
  SignOutIcon,
  MailFillWhite,
  NotifBellFilledWhite,
  ProfileFillWhite,
  HamburgerMenuWhite,
  SignOutIconWhite,
  SignInIconWhite,
  SignInIconBlack,
} from "../assets/images/index";
import snowingPineLogo from "../assets/images/snowingpine_logo.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "../utils/updatemetadata";
import Session from "supertokens-auth-react/recipe/session";

interface MobileNavBarProps {
  handleUserClick: () => void;
  handleLoginClick: () => void;
  handleProfileClick: () => void;
  handleInboxClick: () => void;
  handleLogoutClick: () => void;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({
  handleUserClick,
  handleLoginClick,
  handleProfileClick,
  handleInboxClick,
  handleLogoutClick,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] =
    useState(false);
  const navigate = useNavigate();
  const isDarkMode = Cookies.get("theme") === "dark";
  const currentStyles = isDarkMode ? stylesDark : styles;
  const [hasSession, setHasSession] = useState(false);

  const checkSession = async () => {
    const hasSession = await Session.doesSessionExist();
    setHasSession(hasSession);
  };

  useEffect(() => {
    checkSession();
  }, []);

  const handleHamburgerMenuClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
    setIsProfileDropdownVisible(false);
  };

  const handleProfileIconClick = () => {
    setIsProfileDropdownVisible(!isProfileDropdownVisible);
    setIsDropdownVisible(false);
  };

  async function toggleTheme() {
    const newTheme = isDarkMode ? "light" : "dark";
    Cookies.set("theme", newTheme, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    await updateMetadata({ preferences: { theme: newTheme } });
    window.location.reload();
  }

  // Check if the device is iOS and if it's a PWA
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  const topPadding = isIos() && isInStandaloneMode() ? "40px" : "10px";

  return (
    <div>
      <div
        className={currentStyles.mobileNavBar}
        style={{ paddingTop: topPadding }}
      >
        <div className={currentStyles.topRow}>
          <div
            className={currentStyles.logoContainer}
            onClick={() =>
              (window.location.href = "https://www.snowingpine.com")
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src={snowingPineLogo}
              alt="Snowing Pine Logo"
              className={currentStyles.logo}
            />
            <span className={currentStyles.logoText}>Snowing Pine Stories</span>
          </div>
          <div
            className={currentStyles.hamburgerMenu}
            onClick={handleHamburgerMenuClick}
          >
            <div className={currentStyles.hamburgerIcon}>
              <img
                src={isDarkMode ? HamburgerMenuWhite : HamburgerMenuBlack}
                alt="Menu"
              />
            </div>
          </div>
        </div>
        <div className={currentStyles.bottomRow}>
          <div className={currentStyles.icon} onClick={handleUserClick}>
            <img
              src={isDarkMode ? NotifBellFilledWhite : NotifBellFilledBlack}
              alt="Notifications"
            />
          </div>
          <div className={currentStyles.icon} onClick={handleInboxClick}>
            <img src={isDarkMode ? MailFillWhite : MailFillBlack} alt="Inbox" />
          </div>
          <div className={currentStyles.icon} onClick={handleProfileIconClick}>
            <img
              src={isDarkMode ? ProfileFillWhite : ProfileFillBlack}
              alt="Profile"
            />
          </div>
        </div>
      </div>
      <div
        className={`${currentStyles.dropdownMenu} ${
          isDropdownVisible ? currentStyles.show : ""
        }`}
      >
        <div
          className={currentStyles.dropdownItem}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "https://www.snowingpine.com";
          }}
        >
          <a
            href="https://www.snowingpine.com"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Read
          </a>
        </div>
        <div
          className={currentStyles.dropdownItem}
          onClick={(e) => {
            e.preventDefault();
            navigate("/support");
          }}
        >
          <a
            href="/support"
            onClick={(e) => {
              e.preventDefault();
              navigate("/support");
            }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Support
          </a>
        </div>
        <div
          className={currentStyles.dropdownItem}
          onClick={(e) => {
            e.preventDefault();
            navigate("/blog");
          }}
        >
          <a
            href="/blog"
            onClick={(e) => {
              e.preventDefault();
              navigate("/blog");
            }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Blog
          </a>
        </div>
        <div className={currentStyles.searchBar}>
          <input
            type="text"
            className={currentStyles.searchInput}
            placeholder="Search fictions..."
          />
          <button className={currentStyles.searchButton}>Search</button>
        </div>
      </div>
      {isProfileDropdownVisible && (
        <div className={currentStyles.profileDropdownMenu}>
          <div
            className={currentStyles.profileDropdownItem}
            onClick={handleProfileClick}
          >
            <img
              src={isDarkMode ? ProfileFillWhite : ProfileFillBlack}
              alt="Profile"
            />{" "}
            My Profile
          </div>
          <div
            className={currentStyles.profileDropdownItem}
            onClick={handleInboxClick}
          >
            <img src={isDarkMode ? MailFillWhite : MailFillBlack} alt="Inbox" />{" "}
            Inbox
          </div>
          <div
            className={currentStyles.profileDropdownItem}
            onClick={toggleTheme}
          >
            <img
              src={isDarkMode ? LightModeWhiteFill : DarkModeBlack}
              alt="Theme"
            />{" "}
            {isDarkMode ? "Light Mode" : "Dark Mode"}
          </div>
          <div
            className={currentStyles.profileDropdownItem}
            onClick={hasSession ? handleLogoutClick : handleLoginClick}
          >
            <img
              src={
                isDarkMode
                  ? hasSession
                    ? SignOutIconWhite
                    : SignInIconWhite
                  : hasSession
                  ? SignOutIcon
                  : SignInIconBlack
              }
              alt={hasSession ? "Logout" : "Login"}
            />{" "}
            {hasSession ? "Logout" : "Login"}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNavBar;
