import React, { useState, useEffect } from "react";
import styles from "./DashboardNavBar.module.css";
import darkStyles from "./DarkDashboardNavBar.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/snowingpine_logo.png";
import Cookies from "js-cookie";
import { updateMetadata } from "../../utils/updatemetadata";
import {
  LightModeWhiteFill,
  DarkModeWhite,
  PictureThick,
} from "../../assets/images";
import axios from "axios";
import { getApiDomain } from "../../config";

interface Fiction {
  id: number;
  title: string;
}

const DashboardNavBar: React.FC = () => {
  const [showContentSubmenu, setShowContentSubmenu] = useState(false);
  const [showSettingsSubmenu, setShowSettingsSubmenu] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = Cookies.get("theme");
    return savedTheme === "dark";
  });
  const [showBackgroundOverlay, setShowBackgroundOverlay] = useState(() => {
    const savedBackground = Cookies.get("background");
    return savedBackground === "true";
  });
  const [fictions, setFictions] = useState<Fiction[]>([]);
  const [selectedFiction, setSelectedFiction] = useState<Fiction | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.set("theme", isDarkMode ? "dark" : "light", {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
  }, [isDarkMode]);

  useEffect(() => {
    Cookies.set("background", showBackgroundOverlay ? "true" : "false", {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
  }, [showBackgroundOverlay]);

  useEffect(() => {
    const fetchFictions = async () => {
      try {
        const response = await axios.get<{ fictions: Fiction[] }>(
          `${getApiDomain()}/api/cashable/fictions/ownfictions/textlist`
        );
        let fetchedFictions = response.data.fictions;
        const savedFictionId = Cookies.get("selectedFictionId");
        if (savedFictionId) {
          const savedFiction = fetchedFictions.find(
            (f) => f.id === parseInt(savedFictionId, 10)
          );
          if (savedFiction) {
            setSelectedFiction(savedFiction);
            fetchedFictions = [
              savedFiction,
              ...fetchedFictions.filter((f) => f.id !== savedFiction.id),
            ];
          }
        } else if (fetchedFictions.length > 0) {
          setSelectedFiction(fetchedFictions[0]);
        }
        setFictions(fetchedFictions);
      } catch (error) {
        console.error("Error fetching fictions:", error);
      }
    };

    fetchFictions();
  }, []);

  const toggleTheme = async () => {
    const newTheme = isDarkMode ? "light" : "dark";
    setIsDarkMode(!isDarkMode);
    const updates = {
      preferences: {
        theme: newTheme,
      },
    };
    await updateMetadata(updates);
    Cookies.set("theme", newTheme, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    window.location.reload();
  };

  const toggleBackgroundOverlay = () => {
    setShowBackgroundOverlay(!showBackgroundOverlay);
  };

  const handleFictionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value, 10);
    const fiction = fictions.find((f) => f.id === selectedId);
    if (fiction) {
      setSelectedFiction(fiction);
      Cookies.set("selectedFictionId", fiction.id.toString(), {
        expires: 365,
        sameSite: "None",
        secure: true,
      });
      setFictions([fiction, ...fictions.filter((f) => f.id !== fiction.id)]);
    }
  };

  const handleGoClick = () => {
    if (selectedFiction) {
      window.location.href = `/author-dashboard/dashboard/${selectedFiction.id}`;
    }
  };

  const currentStyles = isDarkMode ? darkStyles : styles;

  return (
    <>
      <div className={currentStyles.navbar}>
        <div
          className={currentStyles.logo}
          onClick={() => (window.location.href = "https://www.snowingpine.com")}
        >
          <img
            className={currentStyles.logoImage}
            src={logo}
            alt="Snowing Pine Stories Logo"
          />
          Snowing Pine Stories
        </div>
        <div className={currentStyles.scrollableContent}>
          <button
            className={currentStyles.navButton}
            onClick={() => navigate("/author-dashboard")}
          >
            Author Dashboard
          </button>
          <button
            className={currentStyles.navButton}
            onClick={() => navigate("/author-dashboard/submissions")}
          >
            Submissions
          </button>
          <button
            className={currentStyles.navButton}
            onClick={() => navigate("/author-dashboard/patreon")}
          >
            Patreon
          </button>
          <hr className={currentStyles.divider} />
          <div className={currentStyles.dropdown}>
            <select
              className={currentStyles.dropdownSelect}
              onChange={handleFictionSelect}
              value={selectedFiction ? selectedFiction.id : ""}
            >
              {fictions.length > 0 ? (
                fictions.map((fiction) => (
                  <option key={fiction.id} value={fiction.id}>
                    {fiction.title.length > 17
                      ? `${fiction.title.slice(0, 17)}...`
                      : fiction.title}
                  </option>
                ))
              ) : (
                <option>No Fictions</option>
              )}
            </select>
            <button className={currentStyles.goButton} onClick={handleGoClick}>
              Go
            </button>
          </div>
          <div className={currentStyles.sectionTitle}>FICTION</div>
          <button
            className={currentStyles.navButton}
            onClick={() =>
              selectedFiction &&
              (window.location.href = `/author-dashboard/dashboard/${selectedFiction.id}`)
            }
          >
            Dashboard
          </button>
          <button
            className={currentStyles.navButton}
            onClick={() => setShowContentSubmenu(!showContentSubmenu)}
          >
            Content
          </button>
          {showContentSubmenu && (
            <div className={currentStyles.submenu}>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/chapters/new/${selectedFiction.id}`)
                }
              >
                New Chapter
              </button>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/chapters/list/${selectedFiction.id}`)
                }
              >
                Chapters
              </button>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/chapters/drafts/${selectedFiction.id}`)
                }
              >
                Drafts
              </button>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/chapters/volumes/${selectedFiction.id}`)
                }
              >
                Volumes
              </button>
            </div>
          )}
          <button
            className={currentStyles.navButton}
            onClick={() => setShowSettingsSubmenu(!showSettingsSubmenu)}
          >
            Settings
          </button>
          {showSettingsSubmenu && (
            <div className={currentStyles.submenu}>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/settings/edit/${selectedFiction.id}`)
                }
              >
                Edit
              </button>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/settings/customize/${selectedFiction.id}`)
                }
              >
                Customize
              </button>
              <button
                className={currentStyles.submenuButton}
                onClick={() =>
                  selectedFiction &&
                  (window.location.href = `/author-dashboard/settings/delete/${selectedFiction.id}`)
                }
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <div className={currentStyles.bottomButtons}>
          <button className={currentStyles.bottomButton} onClick={toggleTheme}>
            <img
              src={isDarkMode ? LightModeWhiteFill : DarkModeWhite}
              alt="Toggle Theme"
            />
            <span>{isDarkMode ? "Light" : "Dark"}</span>
          </button>
          <button
            className={`${currentStyles.bgButton} ${
              showBackgroundOverlay ? currentStyles.bgButtonToggled : ""
            }`}
            onClick={toggleBackgroundOverlay}
          >
            <img src={PictureThick} alt="Background" />
          </button>
        </div>
      </div>
      {showBackgroundOverlay && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: isDarkMode ? "#333333" : "#e0e0e0",
            zIndex: 0,
          }}
        ></div>
      )}
    </>
  );
};

export default DashboardNavBar;
