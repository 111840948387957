import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LightModeWhiteFill,
  DarkModeBlack,
  HomeIconWhite,
  HomeIconBlack,
} from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";

interface ILink {
  name: string;
  onClick: () => void;
  icon: string;
}

export default function NotFoundContentView() {
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = Cookies.get("theme");
    return savedTheme === "dark";
  });

  async function toggleTheme() {
    const newTheme = isDarkMode ? "light" : "dark";
    setIsDarkMode(!isDarkMode);
    const updates = {
      preferences: {
        theme: newTheme,
      },
    };
    await updateMetadata(updates);
    Cookies.set("theme", newTheme, {
      sameSite: "None",
      secure: true,
    });
  }

  function returnToHome() {
    window.location.href = "https://www.snowingpine.com";
  }

  const currentStyles = isDarkMode ? darkStyles : styles;

  return (
    <>
      <div className={`${currentStyles.mainContainer} home-content-view`}>
        <div className={currentStyles.innerContent}>
          <h1>ERROR 404</h1>
          <h2>Sorry, this page does not exist</h2>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className={currentStyles.button} onClick={returnToHome}>
              <img
                src={isDarkMode ? HomeIconWhite : HomeIconBlack}
                alt="Home Icon"
              />
              <span style={{ color: isDarkMode ? "#ffffff" : "#2c3e50" }}>
                Return to Home?
              </span>
            </button>
          </div>
        </div>
        <div className={currentStyles.bottomLinksContainer}>
          <button className={currentStyles.button} onClick={toggleTheme}>
            <img
              src={isDarkMode ? LightModeWhiteFill : DarkModeBlack}
              alt="Toggle Theme"
            />
            <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
          </button>
        </div>
      </div>
    </>
  );
}
